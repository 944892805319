interface IProps {
  header: string;
  text: string;
  display: boolean;
  setDisplay: any;
}

function PopupComponent(props: IProps) {
  const { text, display, setDisplay, header } = props;

  const close = () => {
    if (setDisplay) setDisplay(false);
  };

  return (
    <div
      id="popup"
      className={`${
        display ? "tw-visible" : "tw-invisible"
      } tw-border-red-100 tw-rounded-lg tw-border-[1px] tw-fixed tw-w-[99%] sm:tw-w-[40%] sm:tw-left-[25%] tw-bottom-0 tw-z-50 tw-min-h-[20rem] tw-mx-auto tw-bg-[#050913]`}
    >
      <div className="">
        <div
          className="tw-text-4xl tw-text-right tw-pr-2 tw-cursor-pointer"
          onClick={() => close()}
        >
          x
        </div>
        <div className="tw-p-[1rem] tw-overflow-y-auto tw-max-h-[20rem]">
          <h1 className="tw-text-center tw-pd-[1rem]">{header}</h1>
          <div className="">{text}</div>
        </div>
      </div>
    </div>
  );
}

export default PopupComponent;
