/**
 * Replaces a specified domain in all string values of an object with a given mask.
 * @param obj - The object to be processed.
 * @param domain - The domain to be replaced.
 * @param mask - The mask to replace the domain with.
 * @returns The modified object with the specified domain replaced by the mask in all string values.
 */
export const replaceDomain = (obj: any, domain: string, mask: string): any => {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      obj[key] = replaceDomain(obj[key], domain, mask); // Added missing 'mask' parameter
    } else if (typeof obj[key] === "string") {
      obj[key] = obj[key].replace(mask, domain); // Replaced 'mask' with 'domain' in replace method
    }
  }
  return obj;
}