import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import ReactFlagsSelect from "react-flags-select";
const languageList = ["PL", "RU", "GB"];

export default function LanguageChooserComponent() {
  const { locale, pathname, query } = useRouter();
  let language;
  switch (locale) {
    case "en":
      language = "GB";
      break;
    case "pl":
      language = "PL";
      break;
    case "ru":
      language = "RU";
      break;
    default:
      language = "PL";
  }
  const [lang, setlang] = useState(language);

  useEffect(() => {
    let loc;
    switch (lang) {
      case "GB":
        loc = "/en";
        break;
      case "PL":
        loc = "/pl";
        break;
      case "RU":
        loc = "/ru";
        break;
      default:
        loc = "/pl";
    }
    if (window && loc !== `/${locale}`) {
      let path = pathname;
      if (query?.slug) {
        const slug = (query?.slug as string) || "";
        path = slug ? pathname.replace(/\[slug\]/giu, slug) : "";
      }
      const location = `${loc === "/pl" ? "" : loc}${path}`;
      window.document.location = `${location}`;
    }
  }, [lang, locale, pathname, query]);

  return (
    <ReactFlagsSelect
      fullWidth={false}
      className="text-black"
      selectButtonClassName="text-black"
      countries={languageList}
      customLabels={{
        GB: "EN",
        PL: "PL",
        RU: "RU",
      }}
      selected={lang}
      onSelect={(countryCode: string) => setlang(countryCode)}
      showSelectedLabel={false}
      showOptionLabel={false}
    />
  );
}
