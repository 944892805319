import { useRouter } from "next/router";
import Link from "next/link";
import translation from "./locale/index.json";
import getLanguage from "@/app/utils/getlanguage";
import LanguageChooserComponent from "../LanguageChooser";

function TopComponent() {
  const { locale } = useRouter();
  const lang = getLanguage(translation, locale);
  const content = lang.content;

  return (
    <>
      <div className="tw-w-full tw-grid tw-grid-flow-col tw-grid-cols-2 tw-pt-1 sm:tw-pt-4">
        <div className="tw-justify-start">
          <Link href="/">
            <h1 className="tw-text-large sm:tw-text-2xl">{content.title}</h1>
          </Link>
        </div>
        <div className="tw-grid tw-justify-end">
          <LanguageChooserComponent />
        </div>
      </div>
    </>
  );
}

export default TopComponent;
