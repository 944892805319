export interface IFaqObject {
  "@type": string;
  name: string;
  acceptedAnswer: {
    "@type": string;
    text: string;
  };
}

export interface IFaq {
  LdObjectFaq: IFaqObject[];
}

function Faq2(props: IFaq) {
  const { LdObjectFaq } = props;

  return (
    <>
      <div className="tw-container tw-mx-auto tw-p-[1rem]">
        <h1 className="tw-text-4xl tw-pt-[1rem] tw-pd-[1rem]">FAQ</h1>
        <div>
          {LdObjectFaq.map((item, index) => (
            <div key={index}>
              <h4 className="tw-text-2xl tw-pt-[1rem]">{item.name}</h4>
              <p className="tw-pt-[1rem]">{item.acceptedAnswer.text}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Faq2;
