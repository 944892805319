import { useState } from "react";
import getConfig from "next/config";
import Link from "next/link";
import { useRouter } from "next/router";
import getLanguage from "@/app/utils/getlanguage";
import translation from "./locale/index.json";
import PopupComponent from "../Popup";

function FooterComponent() {
  const { locale } = useRouter();
  const { publicRuntimeConfig } = getConfig();
  const MAIL_ADDRESS = publicRuntimeConfig.MAIL_ADDRESS || null;

  const { content } = getLanguage(translation, locale);
  const [popupDisplay, setPopuDisplay] = useState(false);
  const [popupText, setPopuText] = useState("");
  const [popupTextHeader, setPopuTextHeader] = useState("");
  const [inputNewsletter, setInputNewsletter] = useState("");

  const submit = async () => {
    try {
      if (!inputNewsletter) {
        alert(`${content.mailEmpty}`);
        return;
      }
      const response = await fetch("/api/newsletter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "STS",
          email: MAIL_ADDRESS,
          message: `Newsletter user: ${inputNewsletter}`,
          attachment: undefined,
        }),
      });

      if (response.ok) {
        // console.log("Email sent successfully");
        alert(`${content.mailOK}`);
        setInputNewsletter("");
      } else {
        // console.error("Error sending email:", response.statusText);
        alert(`${content?.mailErr}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const popup = (index: number) => {
    switch (index) {
      case 0:
        setPopuTextHeader(content.agreementHeader);
        setPopuText(content.agreement);
        setPopuDisplay(true);
        break;
      case 1:
        setPopuTextHeader(content.privacyPolicyHeader);
        setPopuText(content.privacyPolicy);
        setPopuDisplay(true);
        break;
    }
  };

  return (
    <>
      <PopupComponent
        text={popupText}
        display={popupDisplay}
        setDisplay={setPopuDisplay}
        header={popupTextHeader}
      />

      <footer className="footer-area">
        <div className="tw-container tw-mx-auto">
          <div className="footer-top-area pt-100">
            <div className="tw-grid tw-items-start sm:tw-grid-flow-col sm:tw-grid-cols-4 tw-gap-2 tw-m-2">
              <div className="sm:tw-col-span-2">
                <div className="single-footer-widget">
                  <Link href="/" className="logo">
                    <h2 className="tw-text-3xl">{content.companyName}</h2>
                  </Link>
                  <p>{content.shortInfo}</p>
                  <ul className="social-links">
                    <li>
                      <Link href="https://www.facebook.com/" target="_blank">
                        <i className="ri-facebook-fill"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href="https://www.instagram.com/" target="_blank">
                        <i className="ri-instagram-line"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href="https://www.linkedin.com/" target="_blank">
                        <i className="ri-linkedin-fill"></i>
                      </Link>
                    </li>
                    <li>
                      <Link href="https://www.youtube.com/" target="_blank">
                        <i className="ri-youtube-line"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="">
      <div className="single-footer-widget pl-5">
        <h3>Links</h3>
        <ul className="links-list">
          <li>
            <Link href="/">Home</Link>
          </li>
          <li>
            <Link href="/about">About Us</Link>
          </li>
          <li>
            <Link href="/pricing">Pricing</Link>
          </li>
          <li>
            <Link href="/blog">Blog</Link>
          </li>
          <li>
            <Link href="/contact">Contact Us</Link>
          </li>
        </ul>
      </div>
    </div> */}
              <div className="">
                <div className="single-footer-widget">
                  <h3>{content.legal}</h3>
                  <ul className="links-list">
                    {/* <li>
                      <Link href="#popup" onClick={() => popup(0)}>
                        {content.term}
                      </Link>
                    </li> */}
                    <li>
                      <Link href="#popup" onClick={() => popup(1)}>
                        {content.privacy}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="">
                <div className="single-footer-widget">
                  <h3>{content.newsletter}</h3>
                  <div className="footer-newsletter-info">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: content.newsletterParagraf,
                      }}
                    ></p>
                    <form className="newsletter-form" data-toggle="validator">
                      <label>
                        <i className="bx bx-envelope-open"></i>
                      </label>
                      <input
                        value={inputNewsletter}
                        onChange={(e) =>
                          setInputNewsletter(e?.target?.value || "")
                        }
                        type="text"
                        className="input-newsletter"
                        placeholder={content.placeholder}
                        name="EMAIL"
                        required
                        autoComplete="off"
                      />
                      <button
                        type="button"
                        className="default-btn tw-min-h-[3rem] tw-w-full sm:tw-mx-[1.8rem]"
                        onClick={() => submit()}
                      >
                        <div className="tw-grid tw-grid-flow-col tw-grid-cols-6 tw-items-center">
                          <div></div>
                          <div className="">
                            <i className="ri-send-plane-line"></i>
                          </div>

                          <div className="tw-col-span-3 tw-items-end">{content.submit}</div>
                        </div>
                      </button>
                      <div
                        id="validator-newsletter2"
                        className="form-result"
                      ></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-line"></div>
          <div className="footer-bottom-area">
            <p>
              © Copyright |{" "}
              <Link href="https://app.apihub4ai.com" target="_blank">
                AH4AI
              </Link>{" "}
              | {content.copyright}{" "}
            </p>
          </div>
        </div>
        {/* <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div> */}
      </footer>
    </>
  );
}

export default FooterComponent;
