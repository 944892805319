// import { useEffect, useState } from "react";
import Link from "next/link";
// import getConfig from "next/config";
import { motion } from "framer-motion";
// import Image from "next/image";
import { useRouter } from "next/router";
import translation from "./locale/index.json";
import getLanguage from "@/app/utils/getlanguage";
// import car_1 from "../../app/assets/img/car1.png";
// import car_2 from "../../app/assets/img/car2.png";
// import car_3 from "../../app/assets/img/car3.png";
// import car_4 from "../../app/assets/img/car4.png";

function FetusesComponent() {
  const { locale } = useRouter();
  const lang = getLanguage(translation, locale);
  const content = lang.content;
  // const [section, setSection] = useState(0);
  // const [image, setImage] = useState(car_4);
  // const [title, setTitle] = useState(content.title);
  // const [subTitle, setSubTitle] = useState(content.subTitle);
  // const [paragraf, setParagraf] = useState(content.paragraf);
  // const { publicRuntimeConfig } = getConfig();
  // const { PHONE_NUMBER } = publicRuntimeConfig;

  // useEffect(() => {
  //   switch (section) {
  //     case 1:
  //       setImage(car_1);
  //       setTitle(content.title1);
  //       setSubTitle(content.subTitle1);
  //       setParagraf(content.paragraf1);
  //       break;
  //     case 2:
  //       setImage(car_2);
  //       setTitle(content.title2);
  //       setSubTitle(content.subTitle2);
  //       setParagraf(content.paragraf2);
  //       break;
  //     case 3:
  //       setImage(car_3);
  //       setTitle(content.title3);
  //       setSubTitle(content.subTitle3);
  //       setParagraf(content.paragraf3);
  //       break;
  //     default:
  //       setImage(car_4);
  //       setTitle(content.title);
  //       setSubTitle(content.subTitle);
  //       setParagraf(content.paragraf);
  //   }
  // }, [section, setParagraf, setSubTitle, setTitle, setImage, content]);

  interface IFeaturesData {
    id: number;
    desc: string;
    icon: string;
    link: string;
    title: string;
  }
  return (
    <>
      <section id="features" className="fetuses-area fetuses-area-bg pt-70 tw-pb-12">
        <div className="tw-container tw-mx-auto">
          <div className="grid justify-content-center g-0">
            {content.featuresData.map((item: IFeaturesData) => {
              const { id, icon, title, desc } = item;
              return (
                <div key={id} className="col-lg-3 col-md-6">
                  <motion.div
                    className="single-fetuses-box"
                    // onMouseOver={() => setSection(id)}
                    // onClick={() => setSection(id)}
                    initial={{
                      opacity: 0,
                      y: 150,
                    }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 1,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    <Link href={``}>
                      <div className="icon">
                        <i className={icon}></i>
                      </div>
                      <h3>{title}</h3>
                      <p>{desc}</p>
                    </Link>
                  </motion.div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* <section id="fetuses-action" className="fetuses-area pt-70">
        <div className="tw-container tw-mx-auto">
          <div className="grid">
            <div className="image">
              <Image src={image} alt="car image" />
            </div>
            <div className="content">
              <div className="sub-t">{title}</div>
              <h2>{subTitle}</h2>
              <p>{paragraf}</p>
              <div className="default-btn cursor-pointer">
                <Link href={`tel:${PHONE_NUMBER}`}>{content.btnAction}</Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default FetusesComponent;
