import { useState, useEffect } from "react";

export const useTypewriter = (
  text: string,
  speed = 50,
  clear = false
): string | null => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    if (clear) {
      setDisplayText("");
      return;
    }

    let i = -1;
    const typingInterval = setInterval(() => {
      if (i < text.length) {
        i++;
        setDisplayText((prevText) => `${prevText}${text.charAt(i)}`);
      } else {
        clearInterval(typingInterval);
      }
    }, speed);

    return () => {
      clearInterval(typingInterval);
    };
  }, [text, speed, clear]);

  return clear ? null : displayText;
};
