import Link from "next/link";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { getCookie, setCookie } from "cookies-next";
import getLanguage from "@/app/utils/getlanguage";
import translation from "./locale/index.json";

const CookieKey = "sts-cookies";

export default function CookiesWidget() {
  const { locale } = useRouter();
  const { content } = getLanguage(translation, locale);
  const [cook, setCook] = useState(false);

  useEffect(() => {
    const cookies = getCookie(CookieKey) || null;
    if (cookies) setCook(true);
  }, []);

  const setCookies = () => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    setCookie(CookieKey, true, { expires: expirationDate });
    setCook(true);
  };

  return (
    <>
      {!cook ? (
        <div className="cookie-widget">
          <div>
            <h3>{content.title}</h3>
            <div className="p">{content.text}</div>
            <Link href={`#`} onClick={setCookies} className="main-btn">
              {content.btn}
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
