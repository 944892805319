import { useEffect, useState } from "react";
import Link from "next/link";
import getConfig from "next/config";
import Image from "next/image";
import { useRouter } from "next/router";
import translation from "./locale/index.json";
import getLanguage from "@/app/utils/getlanguage";
import car_1 from "../../app/assets/img/car1.png";
import car_2 from "../../app/assets/img/car2.png";
import car_3 from "../../app/assets/img/car3.png";
import car_4 from "../../app/assets/img/car4.png";

function FlipCardComponent() {
  const { locale } = useRouter();
  const lang = getLanguage(translation, locale);
  const content = lang.content;
  const [section, setSection] = useState(0);
  const [image, setImage] = useState(car_4);
  const [title, setTitle] = useState(content.title);
  const [subTitle, setSubTitle] = useState(content.subTitle);
  const [paragraf, setParagraf] = useState(content.paragraf);
  const { publicRuntimeConfig } = getConfig();
  const { PHONE_NUMBER } = publicRuntimeConfig;

  useEffect(() => {
    switch (section) {
      case 0:
        setImage(car_1);
        setTitle(content.title);
        setSubTitle(content.subTitle);
        setParagraf(content.paragraf);
        break;
      case 1:
        setImage(car_2);
        setTitle(content.title1);
        setSubTitle(content.subTitle1);
        setParagraf(content.paragraf1);
        break;
      case 2:
        setImage(car_3);
        setTitle(content.title2);
        setSubTitle(content.subTitle2);
        setParagraf(content.paragraf2);
        break;
      case 3:
        setImage(car_4);
        setTitle(content.title3);
        setSubTitle(content.subTitle3);
        setParagraf(content.paragraf3);
        break;
      default:
        setImage(car_1);
        setTitle(content.title);
        setSubTitle(content.subTitle);
        setParagraf(content.paragraf);
    }
  }, [section, setParagraf, setSubTitle, setTitle, setImage, content]);

  return (
    <>
      <section id="flipCard" className="tw-container tw-mx-auto">
        <div className="tw-grid tw-grid-flow-row sm:tw-grid-flow-col sm:tw-grid-cols-4 tw-gap-4 tw-px-2">
          <Link href="#flip-card">
          <div
            onMouseOver={() => setSection(0)}
            className="tw-cursor-pointer bg-car1"
          >
            <div className="tw-pt-[16rem] tw-h-[20rem] tw-px-4 tw-z-10 tw-text-white tw-font-bold">
              {content.title}
            </div>
          </div>
          </Link>
          <Link href="#flip-card">
          <div
            onMouseOver={() => setSection(1)}
            className="tw-cursor-pointer bg-car2"
          >
            <div className="tw-pt-[16rem] tw-h-[20rem] tw-px-4 tw-z-10 tw-text-white tw-font-bold">
              {content.title1}
            </div>
          </div>
          </Link>
          <Link href="#flip-card">
          <div
            onMouseOver={() => setSection(2)}
            className="tw-cursor-pointer bg-car3"
          >
            <div className="tw-pt-[16rem] tw-h-[20rem] tw-px-4 tw-z-10 tw-text-white tw-font-bold">
              {content.title2}
            </div>
          </div>
          </Link>
          <Link href="#flip-card">
          <div
            onMouseOver={() => setSection(3)}
            className="tw-cursor-pointer bg-car4"
          >
            <div className="tw-pt-[16rem] tw-h-[20rem] tw-px-4 tw-z-10 tw-text-white tw-font-bold">
              {content.title3}
            </div>
          </div>
          </Link>
        </div>
        <div>
          <div id="flip-card" className="tw-grid tw-grid-flow-row sm:tw-grid-flow-col sm:tw-grid-cols-3 tw-gap-2 tw-px-2 tw-pt-4">
            <div className="sm:tw-items-center sm:tw-justify-center tw-grid">
              <Image className="sm:tw-w-[95%]" src={image} alt={""} />
            </div>
            <div className="tw-grid sm:tw-col-span-2">
              <div className="tw-text-xs sm:tw-text-sm tw-font-bold tw-mt-6 tw-p-[.6rem] tw-bg-[--main_color] tw-w-fit tw-max-w-[20rem] tw-text-white tw-h-fit">
                {subTitle}
              </div>
              <h2 className="tw-text-4xl sm:tw-text-5xl tw-font-bold tw-pt-[2rem] sm:tw-pt-[4rem]">
                {title}
              </h2>
              <div className="tw-pt-[2rem]">{paragraf}</div>
              <Link href={`tel:${PHONE_NUMBER}`}>
                <div className="default-btn tw-mt-8 tw-min-h-[3rem] tw-pt-[0.7rem] tw-max-w-[11rem] tw-text-center">
                  {content.btnAction}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FlipCardComponent;
