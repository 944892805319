import getConfig from "next/config";
import { useEffect } from "react";
import { useRouter } from "next/router";
import translation from "./locale/index.json";
import getLanguage from "@/app/utils/getlanguage";
import SeoHeader from "@/components/SeoHeader";
import { replaceDomain } from "@/app/utils/replaceDomain";
import BannerComponent from "@/components/Banner";
import FetusesComponent from "@/components/Fetuses";
import FooterComponent from "@/components/Footer";
import CookiesWidget from "@/components/CookiesWidget";
import Faq2 from "@/components/Faq2";
import FlipCardComponent from "@/components/FlipCard";

export default function IndexPage(props: { cookiesPP: boolean }) {
  const { cookiesPP } = props;
  const { publicRuntimeConfig } = getConfig();
  const { locale } = useRouter();
  const lang = getLanguage(translation, locale);
  const APP_HOST = publicRuntimeConfig.APP_HOST || "http://localhost:3000";
  const headers = replaceDomain(lang.headers, APP_HOST, "XXX");

  useEffect(() => {
    buttonHover();
    window.addEventListener("scroll", calcScrollValue);
    return () => window.removeEventListener("scroll", calcScrollValue);
  }, []);

  // ------------- bottom back to top circle
  const calcScrollValue = () => {
    const scrollProgress = document.getElementById("progress");
    // const progressValue = document.getElementById("progress-value");
    const pos = document.documentElement.scrollTop;
    const calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrollValue = Math.round((pos * 100) / calcHeight);
    if (pos > 100) {
      if (scrollProgress) scrollProgress.style.display = "grid";
    } else {
      if (scrollProgress) scrollProgress.style.display = "none";
    }
    if (scrollProgress)
      scrollProgress.addEventListener("click", () => {
        document.documentElement.scrollTop = 0;
      });
    if (scrollProgress)
      scrollProgress.style.background = `conic-gradient(#7f00ff ${scrollValue}%, #9094a6 ${scrollValue}%)`;
  };

  // ------------- button hover effect
  const buttonHover = () => {
    const mainBtn = document.querySelectorAll(".main-btn");
    mainBtn.forEach((e) => {
      if (e) {
        e.addEventListener("mouseenter", (event) => {
          const rect = e.getBoundingClientRect();
          const offset = {
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
          };
          const relX = (event as MouseEvent).pageX - offset.left;
          const relY = (event as MouseEvent).pageY - offset.top;
          const spanElement = e?.querySelector("span");
          if (spanElement) {
            spanElement.style.top = relY + "px";
            spanElement.style.left = relX + "px";
          }
        });
      }
    });
  };

  return (
    <>
      <SeoHeader
        title={headers.title}
        description={headers.description}
        keyWords={headers.keyWords}
        openGraph={headers.openGraph}
        canonicalLink={headers.canonicalLink}
        metaRobots={headers.metaRobots}
        jsonLd={headers.jsonLd}
      />

      <BannerComponent />
      <FetusesComponent />
      <FlipCardComponent />
      <Faq2 LdObjectFaq={headers?.jsonLd?.faq?.mainEntity} />
      <FooterComponent />
      <div id="progress">
        <span id="progress-value">
          <i className="ri-arrow-up-line"></i>
        </span>
      </div>
      {!cookiesPP ? <CookiesWidget /> : ""}
    </>
  );
}

export async function getServerSideProps(context: any) {
  const cookiesPP = context?.req?.cookies;
  return {
    props: { cookiesPP: cookiesPP["sts-cookies"] || false }, // will be passed to the page component as props
  };
}
