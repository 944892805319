export interface ILanguageTranslate {
  locale: string;
  headers?: Headers;
  content: any;
}

interface Headers {
  title: string;
  description: string;
  keyWords: string;
  canonicalLink: string;
  metaRobots: string;
  openGraph: OpenGraph;
  jsonLd: JsonLd;
}

interface OpenGraph {
  ogTitle: string;
  ogDescription: string;
  ogType: string;
  ogUrl: string;
  ogSiteName: string;
}

interface JsonLd {
  website: Website;
}

interface Website {
  "@context": string;
  "@type": string;
  url: string;
  name: string;
  alternateName: string;
  logo: string;
  sameAs: string[];
}

const getLanguage = (language: ILanguageTranslate[], locale: string | undefined) => {
  return language.filter((item) => item.locale === locale)[0];
};

export default getLanguage;
