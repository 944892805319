import Link from "next/link";
import Image from "next/image";
import man from "../../app/assets/img/man.png";
import translation from "./locale/index.json";
import { useRouter } from "next/router";
import getLanguage from "@/app/utils/getlanguage";
import ChatAIComponent from "../ChatAi";
import TopComponent from "../Top";

function BannerComponent() {
  const { locale } = useRouter();
  const lang = getLanguage(translation, locale);
  const content = lang.content;

  return (
    <div className="banner-area tw-pr-4 tw-pl-4">
      <TopComponent />
      <div className="sm:tw-pt-[2rem] tw-grid tw-grid-flow-row sm:tw-grid-cols-2 sm:tw-grid-flow-col tw-gap-4 sm:tw-gap-0 tw-items-center tw-justify-center">
          <div className="tw-w-full">
            <div className="content">
              <span className="banner-top-title">{content.title}</span>
              <h1>
                <span className="grd-color-1">{content.subTitle1}</span>
                {content.subTitle2}
              </h1>
              <p>{content.paragraf1}</p>
              <ChatAIComponent />
              <div className="popular-tag">
                <p>{content.tag}:</p>
                {content.tags.map((item: string, key: number) => (
                  <Link key={key} href="">
                    {item}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="">
            <div className="tw-float-right">
              <Image className="" src={man} alt="man" priority={true} />
            </div>
          </div>
        </div>
        <div className="scroll-down">
          <Link href="#features">
            <div className="mouse"></div>
          </Link>
        </div>
    </div>
  );
}

export default BannerComponent;
