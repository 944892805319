import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import getLanguage from "@/app/utils/getlanguage";
import translation from "./locale/index.json";
import { IConversationTask } from "@/app/interfaces/conversationTask.interface";
import { useReCaptcha } from "next-recaptcha-v3";
import convertMarkdownToHTML from "@/app/utils/markdown";
import Typewriter from "../Typewriter";
import style from "./style.module.css";

function ChatAIComponent() {
  const { locale } = useRouter();
  const { content } = getLanguage(translation, locale);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState(content.invite);
  const [progress, setProgress] = useState(false);
  const [conversation, setConversation] = useState<IConversationTask[]>([]);
  const { executeRecaptcha } = useReCaptcha();
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    convertMarkdownToHTML(answer).then((txt) => {
      setMarkdown(txt);
    });
  }, [answer, setMarkdown]);

  const action = async () => {
    if (question === "") return;
    const token = (await executeRecaptcha("form_submit")) || null;

    const body = {
      ask: question,
      conversation,
      context:
        `Return the result in a language compatible with the "${locale}" ISO code.` ||
        undefined,
      token,
      idTask: "",
    };
    setProgress(true);
    fetch("/api/chatai", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then(async (data) => {
        if (data?.data) {
          if (data?.data?.match && data?.data?.match(/FINISH_ACTION/gu)) {
            setConversation([]);
            data.data = content.thx || "THX.";
          } else {
            setConversation([
              ...conversation,
              {
                user: body.ask,
                assistant: data.data,
              },
            ]);
          }
          if (typeof data?.data === "string") {
            setAnswer(data.data);
          } else {
            setAnswer(content.error);
          }
          setQuestion("");
          setProgress(false);
        }
      });
  };

  return (
    <>
      <div className="tw-rounded-lg tw-w-full tw-bg-white tw-text-black tw-mb-4 tw-max-h-[25rem]">
        {progress ? (
          <div className="tw-w-full">
            <div
              id="progressChat"
              className={`tw-w-[100%] tw-h-8 tw-bg-blue-200 tw-text-center tw-font-semibold tw-pt-1`}
            >
              {content.progress}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="tw-grid tw-grid-flow-row tw-grid-rows-1 tw-w-full tw-h-[17rem]">
          <div className="tw-grid">
            <Typewriter
              className={`${style.all}`}
              text={markdown || " "}
              maxHeight={"17rem"}
            />
          </div>
          <div className="tw-grid tw-h-[3rem]">
            <div className="tw-grid tw-grid-flow-col tw-grid-cols-2 tw-gap-1 tw-items-start tw-justify-center">
              <div className="tw-grid tw-w-full tw-h-[2rem] tw-px-2">
                <input
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") action();
                  }}
                  type="text"
                  className="tw-h-[2.5rem] tw-px-1 tw-w-full"
                  placeholder={content.placeholder}
                />
              </div>
              <div className="tw-grid tw-w-full tw-px-1">
                <button className="btn" type="button" onClick={() => action()}>
                  {content.btn}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatAIComponent;
